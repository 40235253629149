import React, { useState, useEffect } from 'react';
import '../style/pages/Webhooks.css';

function Webhooks() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [scope, setScope] = useState('');
    const [destination, setDestination] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [headers, setHeaders] = useState({ property1: '', property2: '' });
    const [webhooks, setWebhooks] = useState([]);
    const [selectedWebhook, setSelectedWebhook] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        fetch(`${API_URL}/bigcommerce_webhooks/hooks`)
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    setWebhooks(data.data);
                } else {
                    setWebhooks([]);
                }
            })
            .catch(error => {
                console.error('Error fetching webhooks:', error);
                setWebhooks([]); // Reset webhooks on error
            });
    }, [API_URL]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const webhookData = {
            scope,
            destination,
            is_active: isActive,
            headers: {
                ...(headers.property1 && { property1: headers.property1 }),
                ...(headers.property2 && { property2: headers.property2 })
            }
        };

        const url = isEditMode && selectedWebhook
            ? `${API_URL}/bigcommerce_webhooks/update-webhook/${selectedWebhook.id}`
            : `${API_URL}/bigcommerce_webhooks/create-webhook`;

        fetch(url, {
            method: isEditMode ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(webhookData),
        })
            .then(response => response.json())
            .then(data => {
                if (isEditMode && selectedWebhook) {
                    setWebhooks(webhooks.map(w => (w.id === selectedWebhook.id ? data.data : w)));
                } else {
                    setWebhooks([...webhooks, data.data]);
                }
                resetForm();
            })
            .catch((error) => {
                console.error('Error creating/updating webhook:', error);
            });
    };

    const handleDelete = () => {
        if (!selectedWebhook) return;

        fetch(`${API_URL}/bigcommerce_webhooks/delete_webhook/${selectedWebhook.id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (response.ok) {
                    setWebhooks(webhooks.filter(w => w.id !== selectedWebhook.id));
                    resetForm();
                }
            })
            .catch((error) => {
                console.error('Error deleting webhook:', error);
            });
    };

    const handleEdit = () => {
        if (selectedWebhook) {
            setScope(selectedWebhook.scope);
            setDestination(selectedWebhook.destination);
            setIsActive(selectedWebhook.is_active);
            setIsEditMode(true);
        }
    };

    const handleAddMode = () => {
        resetForm();
    };

    const resetForm = () => {
        setScope('');
        setDestination('');
        setIsActive(true);
        setHeaders({ property1: '', property2: '' });
        setSelectedWebhook(null);
        setIsEditMode(false);
    };

    const handleRowClick = (webhook) => {
        setSelectedWebhook(webhook);
    };

    const handleSyncCustomers = () => {
        fetch(`${API_URL}/bigcommerce_api/sync_customers_from_bigcommerce`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                console.log('Customers synced successfully:', data);
            })
            .catch(error => {
                console.error('Error syncing customers:', error);
            });
    };

    const handleSyncOrders = () => {
        fetch(`${API_URL}/bigcommerce_api/sync_orders_from_bigcommerce`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                console.log('Orders synced successfully:', data);
            })
            .catch(error => {
                console.error('Error syncing orders:', error);
            });
    };

    const handleSyncAll = () => {
        handleSyncCustomers();
        handleSyncOrders();
        // Add additional sync functions if needed
        console.log("All data synced.");
    };

    return (
        <div className="webhooks-wrapper">
            <div className="webhooks-layout">
                {/* Webhooks Form and Table */}
                <div className="create-webhook-container">
                    <h2>{isEditMode ? 'Update Webhook' : 'Create New Webhook'}</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Scope:
                            <select required value={scope} onChange={(e) => setScope(e.target.value)}>
                                <option value="">Select Scope</option>
                                <option value="store/customer/address/created">store/customer/address/created</option>
                                <option value="store/customer/address/updated">store/customer/address/updated</option>
                                <option value="store/customer/address/deleted">store/customer/address/deleted</option>
                                <option value="store/order/created">store/order/created</option>
                                <option value="store/order/updated">store/order/updated</option>
                                <option value="store/customer/created">store/customer/created</option>
                                <option value="store/customer/updated">store/customer/updated</option>
                                <option value="store/customer/deleted">store/customer/deleted</option>
                            </select>
                        </label>
                        <label>
                            Destination:
                            <input
                                type="text"
                                value={destination}
                                required
                                onChange={(e) => setDestination(e.target.value)}
                                placeholder="https://your-domain.com/webhooks"
                            />
                        </label>
                        <label>
                            Active:
                            <input
                                type="checkbox"
                                checked={isActive}
                                onChange={() => setIsActive(!isActive)}
                            />
                        </label>
                        <div className="headers-container">
                            <h3>Headers (Optional)</h3>
                            <label>
                                Property 1:
                                <input
                                    type="text"
                                    value={headers.property1}
                                    onChange={(e) => setHeaders({ ...headers, property1: e.target.value })}
                                />
                            </label>
                            <label>
                                Property 2:
                                <input
                                    type="text"
                                    value={headers.property2}
                                    onChange={(e) => setHeaders({ ...headers, property2: e.target.value })}
                                />
                            </label>
                        </div>
                        <button type="submit">{isEditMode ? 'Update Webhook' : 'Create Webhook'}</button>
                    </form>
                </div>
                <div className="webhooks-table-container">
                    <div className="table-actions">
                        <div className="table-title">
                            <h2>Webhooks</h2>
                        </div>
                        <div className="table-buttons">
                            <button onClick={handleEdit} disabled={!selectedWebhook} className="edit-button">Edit</button>
                            <button onClick={handleDelete} disabled={!selectedWebhook} className="delete-button">Delete</button>
                            {isEditMode && (
                                <button onClick={handleAddMode} className="add-button">Add</button>
                            )}
                        </div>
                    </div>

                    <div className="webhooks-table-header">
                        <span>ID</span>
                        <span>SCOPE</span>
                        <span>URL</span>
                        <span>ACTIVE</span>
                    </div>
                    {webhooks.length > 0 ? (
                        webhooks.map((webhook) => (
                            webhook && webhook.id !== undefined && (
                                <div
                                    className={`webhooks-table-row ${selectedWebhook && selectedWebhook.id === webhook.id ? 'selected' : ''}`}
                                    key={webhook.id}
                                    onClick={() => handleRowClick(webhook)}
                                >
                                    <span>{webhook.id}</span>
                                    <span>{webhook.scope}</span>
                                    <span>{webhook.destination}</span>
                                    <span>{webhook.is_active ? 'Yes' : 'No'}</span>
                                </div>
                            )
                        ))
                    ) : (
                        <div className="webhooks-table-row">
                            <span colSpan="4">No webhooks available</span>
                        </div>
                    )}
                </div>
            </div>

            {/* Sync Buttons */}
            <div className="sync-buttons-container">
                <button className="sync-button customers" onClick={handleSyncCustomers}>Sync Customers</button>
                <button className="sync-button orders" onClick={handleSyncOrders}>Sync Orders</button>
                <button className="sync-button all" onClick={handleSyncAll}>Sync All</button>
            </div>
        </div>
    );
}

export default Webhooks;
