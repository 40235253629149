import React, { useState, useEffect } from 'react';
import "../style/components/Header.css";
import { FaSignOutAlt } from "react-icons/fa";  // Import the specific icon
import { useNavigate } from 'react-router-dom';

function Header({ title }) {

    const [time, setTime] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            const californiaTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
            setTime(californiaTime);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("access_token"); // Remove the token from local storage
        navigate("/login"); // Redirect to the login page
    };

    return (
        <div className="header-container">
            <div className="left-header-container">
                <h2>{title}</h2>
                <p>{time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}</p>
            </div>
            <div className="right-header-container">
                <FaSignOutAlt
                    className="logout-icon"  // Use the icon as a React component
                    onClick={handleLogout}   // Attach the logout function to the onClick event
                    style={{ cursor: 'pointer' }} // Make it look clickable
                />
            </div>
        </div>
    );
}

export default Header;
