import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../style/components/Popups/CreateRepModal.css';
import { statesAndProvinces } from '../../datasets/statesAndProvinces';

function UpdateRepModal({ isOpen, onClose, onSubmit, assignedStates, repData }) {
    const API_URL = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        status: 'active',
        states_or_provinces: [],
    });

    useEffect(() => {
        if (repData) {
            setFormData({
                username: repData.username || '',
                email: repData.email || '',
                first_name: repData.first_name || '',
                last_name: repData.last_name || '',
                status: repData.status || 'active',
                states_or_provinces: (repData.states || []).filter(state => state && state.state).map(state => ({
                    label: state.state.toLowerCase(),
                    value: state.state.toLowerCase(),
                    start_datetime: new Date(state.start_datetime),
                    isExisting: true,  // Mark as existing state
                    isEditable: false, // Initially set to not editable
                })),
            });
        }
    }, [repData]);

    // Filter available states based on those already assigned and not already selected
    const availableStatesAndProvinces = statesAndProvinces.filter(
        state =>
            !assignedStates.includes(state.value.toLowerCase()) && // Exclude assigned states
            !formData.states_or_provinces.some(selectedState => selectedState.value === state.value.toLowerCase()) // Exclude already selected states
    );

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleStatesChange = (selectedOptions) => {
        const updatedStates = selectedOptions ? selectedOptions.map(option => {
            const existingState = formData.states_or_provinces.find(state => state.value === option.value);
            return {
                label: option.value.toLowerCase(),
                value: option.value.toLowerCase(),
                start_datetime: existingState ? existingState.start_datetime : new Date(),
                isExisting: existingState ? existingState.isExisting : false,
                isEditable: existingState ? existingState.isEditable : true,
            };
        }) : [];
        setFormData({
            ...formData,
            states_or_provinces: updatedStates,
        });
    };

    const handleDateChange = (index, date) => {
        const updatedStates = [...formData.states_or_provinces];
        updatedStates[index].start_datetime = date;
        setFormData({
            ...formData,
            states_or_provinces: updatedStates,
        });
    };

    const handleToggleEditable = (index) => {
        const updatedStates = [...formData.states_or_provinces];
        updatedStates[index].isEditable = !updatedStates[index].isEditable;
        setFormData({
            ...formData,
            states_or_provinces: updatedStates,
        });
    };

    const convertToISOWithoutTimezone = (date) => {
        const options = { timeZone: 'America/Los_Angeles', hour12: false };
        const formatter = new Intl.DateTimeFormat('en-US', {
            ...options,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        const parts = formatter.formatToParts(date);
        const formattedDate = `${parts.find(part => part.type === 'year').value}-${parts.find(part => part.type === 'month').value}-${parts.find(part => part.type === 'day').value}T${parts.find(part => part.type === 'hour').value}:${parts.find(part => part.type === 'minute').value}:${parts.find(part => part.type === 'second').value}`;

        return formattedDate;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Filter out states to send only new or editable existing states
        const statesToSubmit = formData.states_or_provinces.filter(state => !state.isExisting || state.isEditable);

        // Prepare submission data to send to the API
        const submissionData = {
            ...formData,
            states_or_provinces: statesToSubmit.map(option => ({
                state: option.value.toLowerCase(),
                start_datetime: convertToISOWithoutTimezone(option.start_datetime),
            })),
        };

        if (formData.status === 'inactive') {
            submissionData.status = 'inactive';
        }

        try {
            const response = await fetch(`${API_URL}/system/reps/update_rep/${repData.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submissionData),
            });

            if (response.ok) {
                const updatedRep = await response.json();
                onSubmit(updatedRep.representative);
                onClose();
            } else {
                console.error('Failed to update representative');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const isInactive = formData.status === 'inactive';

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <h2>Update Representative</h2>
                <form onSubmit={handleSubmit}>
                    {/* Representative form fields */}
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            disabled={isInactive}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={isInactive}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            disabled={isInactive}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            disabled={isInactive}
                        />
                    </div>

                    {/* Multi-select for states or provinces */}
                    <div className="form-group">
                        <label htmlFor="states_or_provinces">States/Provinces</label>
                        <Select
                            id="states_or_provinces"
                            isMulti
                            options={availableStatesAndProvinces}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleStatesChange}
                            placeholder="Select States/Provinces"
                            value={formData.states_or_provinces}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isDisabled={isInactive}
                        />

                        {/* DatePicker for each selected state */}
                        {formData.states_or_provinces.map((state, index) => (
                            <div key={index} className="form-group date-time-group">
                                <label htmlFor={`state-date-${index}`}>
                                    Start Date and Time for {state.label} (California Time)
                                </label>
                                <div className="date-picker-container">
                                    <DatePicker
                                        selected={new Date(state.start_datetime)}
                                        onChange={(date) => handleDateChange(index, date)}
                                        showTimeSelect
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        className="date-picker-input"
                                        disabled={isInactive || !state.isEditable}
                                    />
                                    {state.isExisting && (
                                        <div className="unlock-checkbox-container">
                                            <input
                                                type="checkbox"
                                                className="unlock-checkbox"
                                                checked={state.isEditable}
                                                onChange={() => handleToggleEditable(index)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>

                    {/* Modal buttons */}
                    <div className="modal-buttons">
                        <button type="submit" className="submit-button">Update</button>
                        <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateRepModal;
