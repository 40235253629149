// src/App.js

import React from "react";
import { Routes, Route, useLocation, Navigate, matchPath } from "react-router-dom";
import LeftMenu from "./components/LeftMenu";
import Header from "./components/Header";
import ProtectedRoute from "./ProtectedRoute";
import routeConfig from "./RouteConfig"; // Asegúrate de que la ruta es correcta

function App() {
  const location = useLocation();
  const showMenuAndHeader = location.pathname !== "/login";

  // Función para obtener el título basado en la ruta actual
  const getPageTitle = (pathname) => {
    for (const route of routeConfig) {
      const match = matchPath(
        { path: route.path, end: true },
        pathname
      );
      if (match !== null) {
        return route.title;
      }
    }

    // Si no se encuentra una coincidencia, usar la última parte de la ruta
    const pathSegments = pathname.split("/").filter(Boolean);
    const rawTitle = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : "Dashboard";

    // Reemplazar guiones bajos con espacios y capitalizar
    const titleWithSpaces = rawTitle.replace(/_/g, " ");
    const capitalizedTitle = titleWithSpaces.split(" ").map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(" ");

    return capitalizedTitle;
  };

  const title = getPageTitle(location.pathname);

  return (
    <div className="main-layout">
      {showMenuAndHeader && <LeftMenu />}
      <div className="content-layout">
        <div className="sticky-header-wrapper">
          {showMenuAndHeader && <Header title={title} />}
        </div>
        <Routes>
          {routeConfig.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute
                  element={route.element}
                  allowedRoles={route.allowedRoles}
                />
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
