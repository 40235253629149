import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../style/components/Popups/CreateRepModal.css';
import { statesAndProvinces } from '../../datasets/statesAndProvinces'; // Adjust the path as needed

function CreateRepModal({ isOpen, onClose, onSubmit, assignedStates }) {
    const API_URL = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        first_name: '',
        last_name: '',
        user_role: 'representative',
        status: 'active',
        states: [], // Adjusted to include detailed state information
    });

    // Effect to filter available states based on those already assigned
    const [filteredStates, setFilteredStates] = useState([]);

    useEffect(() => {
        // Flatten the list of assigned states into a single array of state names
        const assignedStateNames = assignedStates
            .filter(stateObj => stateObj && stateObj.state) // Ensure valid state objects
            .map(stateObj => stateObj.state.toLowerCase());

        // Filter states based on those already assigned
        const availableStatesAndProvinces = statesAndProvinces.filter(
            state => !assignedStateNames.includes(state.value.toLowerCase())
        );

        setFilteredStates(availableStatesAndProvinces);
    }, [assignedStates]); // Re-run when assignedStates change

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleStatesChange = (selectedOptions) => {
        // Initialize states with start_datetime, converting state values to lowercase
        const statesWithDates = selectedOptions ? selectedOptions.map(option => ({
            state: option.value.toLowerCase(), // Convert state to lowercase
            start_datetime: new Date() // Default to current date/time
        })) : [];
        setFormData({
            ...formData,
            states: statesWithDates,
        });
    };

    const handleDateChange = (index, date) => {
        const updatedStates = [...formData.states];
        updatedStates[index].start_datetime = date;
        setFormData({
            ...formData,
            states: updatedStates,
        });
    };

    const convertToISOWithoutTimezone = (date) => {
        // Convert the date to California time zone without changing the actual time
        const options = { timeZone: 'America/Los_Angeles', hour12: false };
        const formatter = new Intl.DateTimeFormat('en-US', {
            ...options,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        const parts = formatter.formatToParts(date);
        const formattedDate = `${parts.find(part => part.type === 'year').value}-${parts.find(part => part.type === 'month').value}-${parts.find(part => part.type === 'day').value}T${parts.find(part => part.type === 'hour').value}:${parts.find(part => part.type === 'minute').value}:${parts.find(part => part.type === 'second').value}`;

        return formattedDate;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Format states with ISO string for API compatibility in California time
        const formattedStates = formData.states.map(state => ({
            ...state,
            start_datetime: convertToISOWithoutTimezone(state.start_datetime) // Convert to California timezone format
        }));

        try {
            const response = await fetch(`${API_URL}/system/reps/create_rep`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData, states: formattedStates }),
            });

            if (response.ok) {
                const newRep = await response.json();
                onSubmit(newRep.representative);
                onClose();
            } else {
                console.error('Failed to create representative');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <h2>Create New Representative</h2>
                <form onSubmit={handleSubmit} className="form-content">
                    {/* Form fields for representative details */}
                    <div className="form-section">
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="first_name">First Name</label>
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="last_name">Last Name</label>
                            <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    {/* Multi-select for states or provinces with scrolling container */}
                    <div className="form-group scrollable-section">
                        <label htmlFor="states_or_provinces">States/Provinces</label>
                        <Select
                            id="states_or_provinces"
                            isMulti
                            options={filteredStates} // Use filtered states
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleStatesChange}
                            placeholder="Select States/Provinces"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />

                        {/* Input for start date and time for each selected state */}
                        {formData.states.map((state, index) => (
                            <div key={index} className="form-group date-time-group">
                                <label htmlFor={`state-date-${index}`}>
                                    Start Date and Time for {state.state} (California Time)
                                </label>
                                <DatePicker
                                    selected={new Date(state.start_datetime)}
                                    onChange={(date) => handleDateChange(index, date)}
                                    showTimeSelect
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    className="date-picker-input"
                                />
                            </div>
                        ))}
                    </div>

                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                        >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>

                    <div className="modal-buttons">
                        <button type="submit" className="submit-button">Create</button>
                        <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateRepModal;
