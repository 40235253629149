

import React from "react";
import {
    FaTachometerAlt,
    FaUsers,
    FaConnectdevelop,
} from "react-icons/fa";
import Representatives from "./pages/Representatives";
import Webhooks from "./pages/WebHooks";
import OriginalDashboard from "./pages/ProductionDashboard"; // Asegúrate de importar este componente
import OriginalRepresentatives from "./pages/ProductionRepresentatives";
import RepresentativePage from "./pages/RepresentativePage";

const routeConfig = [
    {
        path: "/dashboard",
        title: "Dashboard",
        element: OriginalDashboard, // Correcto: componente React
        allowedRoles: ["master", "admin"],
        icon: <FaTachometerAlt />,
        submenu: [],
    },
    {
        path: "/reps/dashboard",
        title: "Representatives Dashboard",
        element: OriginalRepresentatives, // Correcto: componente React
        allowedRoles: ["master", "admin"],
        icon: <FaUsers />,
        submenu: [
            { title: "Manage Reps", path: "/reps" },
        ],
    },
    {
        path: "/webhooks",
        title: "Webhooks",
        element: Webhooks, // Correcto: componente React
        allowedRoles: ["master"],
        icon: <FaConnectdevelop />,
        submenu: [
            { title: "Webhooks", path: "/webhooks" },
        ],
    },



    // Agrega más rutas y submenús según sea necesario SUBMENUS !!!!!!!!

    // Rutas dinámicas o adicionales
    {
        path: "/reps",
        title: "Manage Reps",
        element: Representatives, // O el componente correspondiente
        allowedRoles: ["master", "admin"],
    },
    {
        path: "/reps/:id/stats",
        title: "Representative Stats",
        element: RepresentativePage, // O el componente correspondiente
        allowedRoles: ["master", "admin"],
    },

];

export default routeConfig;