import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/pages/Representatives.css';
import image from "../assets/logo.jpeg";
import CreateRepModal from '../components/Popups/CreateRepModal';
import UpdateRepModal from '../components/Popups/UpdateRepModal';

function Representatives() {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [representatives, setRepresentatives] = useState([]);
    const [assignedStates, setAssignedStates] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedRep, setSelectedRep] = useState(null);

    const fetchRepresentatives = useCallback(() => {
        fetch(`${API_URL}/system/reps/all_reps`)
            .then(response => response.json())
            .then(data => {
                setRepresentatives(data.representatives);

                // Gather all assigned states
                const allAssignedStates = data.representatives.flatMap(rep => rep.states);
                setAssignedStates(allAssignedStates);
            })
            .catch(error => {
                console.error('Error fetching representatives:', error);
            });
    }, [API_URL]);

    useEffect(() => {
        fetchRepresentatives();
    }, [fetchRepresentatives]);

    const handleOpenCreateModal = () => setIsCreateModalOpen(true);
    const handleCloseCreateModal = () => setIsCreateModalOpen(false);
    const handleCreateRep = (newRep) => {
        setRepresentatives([...representatives, newRep]);
        fetchRepresentatives(); // Fetch data after creating a new rep
    };

    const handleOpenUpdateModal = (rep) => {
        setSelectedRep(rep);
        setIsUpdateModalOpen(true);
    };

    const handleCloseUpdateModal = () => setIsUpdateModalOpen(false);
    const handleUpdateRep = () => {
        fetchRepresentatives(); // Fetch the updated list of representatives after updating
        setIsUpdateModalOpen(false);
    };

    const handleCardClick = (rep) => {
        console.log("Rep clicked:", rep);
        navigate(`/reps/${rep.id}/stats`, { state: { rep_id: rep.id, repStates: rep.states, username: rep.username } });
    };

    return (
        <div className="representatives-layout">
            <button className="create-rep-button" onClick={handleOpenCreateModal}>
                Create New Rep Account
            </button>

            <div className="representatives-grid">
                {representatives.map(rep => (
                    <div className="representatives-card" key={rep.id} onClick={() => handleCardClick(rep)}>
                        <div className="representatives-logo">
                            <img src={image} alt="Logo" />
                        </div>
                        <div className="representatives-info">
                            <h2 className="representatives-name">{rep.username}</h2>
                            <p className="representatives-email">{rep.email}</p>
                            <p className="representatives-areas">
                                States: {rep.states ? rep.states.map(state => state.state).join(', ') : 'N/A'}
                            </p>
                            <p className="representatives-customers">Customers: {rep.customer_count}</p>
                            <p className="representatives-status">Status: {rep.status}</p>
                            <button onClick={(e) => { e.stopPropagation(); handleOpenUpdateModal(rep); }}>Update</button>
                        </div>
                    </div>
                ))}
            </div>

            <CreateRepModal
                isOpen={isCreateModalOpen}
                onClose={handleCloseCreateModal}
                onSubmit={handleCreateRep}
                assignedStates={assignedStates}
            />

            <UpdateRepModal
                isOpen={isUpdateModalOpen}
                onClose={handleCloseUpdateModal}
                onSubmit={handleUpdateRep}
                assignedStates={assignedStates}
                repData={selectedRep}
            />
        </div>
    );
}

export default Representatives;
