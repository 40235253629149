// src/ProtectedRoute.jsx

import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";

function ProtectedRoute({ element: Component, allowedRoles }) {
    const location = useLocation();
    const token = localStorage.getItem("access_token");

    const getUserRoleFromToken = () => {
        if (!token) return null;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        return decodedToken.user_role;
    };

    const userRole = getUserRoleFromToken();
    const isAuthenticated = Boolean(token) && userRole;

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    localStorage.removeItem("access_token");
                    window.location = "/login";
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    const isTokenExpired = () => {
        if (!token) return true;
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const expDate = new Date(decodedToken.exp * 1000);
        return expDate < new Date();
    };

    if (!isAuthenticated || isTokenExpired()) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!allowedRoles.includes(userRole)) {
        return <Navigate to="/unauthorized" replace />;
    }

    return <Component />;
}

export default ProtectedRoute;
