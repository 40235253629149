import React from 'react';
import '../Test/RepresentativeStats.css';


function RepresentativePage() {



    return (
        <div className="rep-stats-layout">
            <h2>Under Maintenance</h2>
        </div>
    );
}

export default RepresentativePage;
