// src/components/LeftMenu.jsx

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../style/components/LeftMenu.css";
import image from "../assets/logo.jpeg";
import {
    FaChevronRight,
    FaChevronLeft,
} from "react-icons/fa";
import routeConfig from "../RouteConfig"; // Importa el mapa de rutas

function LeftMenu() {
    const [activeMenu, setActiveMenu] = useState(null);
    const [submenuPosition, setSubmenuPosition] = useState({});
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate();

    // Filtra solo las rutas que tienen submenús
    const menuItems = routeConfig.map(route => ({
        title: route.title,
        icon: route.icon,
        submenu: route.submenu || [],
        path: route.path,
    }));

    const handleMenuToggle = () => {
        setIsMenuOpen(prev => !prev);
    };

    const handleMouseEnter = (index, event) => {
        const itemElement = event.currentTarget;
        const itemPosition = itemElement.getBoundingClientRect();
        const isResponsive = window.innerWidth <= 768;

        let leftOffset, topOffset;

        if (isResponsive) {
            leftOffset = isMenuOpen ? 180 : 50;
        } else {
            leftOffset = isMenuOpen ? itemPosition.right : itemPosition.right;
        }

        topOffset = itemPosition.top;

        setSubmenuPosition({
            top: topOffset,
            left: leftOffset,
        });

        setActiveMenu(index);
    };

    const handleMouseLeave = () => {
        setActiveMenu(null);
    };

    const handleMenuItemClick = (path) => {
        if (path) {
            navigate(path);
            if (window.innerWidth <= 768) {
                setIsMenuOpen(false);
            }
        }
    };

    return (
        <nav className={`left-menu ${isMenuOpen ? "open" : ""}`}>
            {/* Logo */}
            <div className="logo">
                <img src={image} alt="Logo" />
            </div>

            {/* Wrapper para scroll */}
            <div className="menu-items-wrapper">
                {/* Items del Menú */}
                <ul className="menu-items">
                    {menuItems.map((item, index) => (
                        <li
                            key={index}
                            className="menu-item"
                            onMouseEnter={(event) => handleMouseEnter(index, event)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div
                                className="menu-item-content"
                                onClick={() => handleMenuItemClick(item.path)}
                                style={{ cursor: "pointer" }}
                            >
                                <span className="menu-icon">{item.icon}</span>
                                {isMenuOpen && <span className="menu-text">{item.title}</span>}
                            </div>

                            {/* Mostrar submenú solo si hay elementos en el submenu */}
                            {item.submenu.length > 0 && activeMenu === index && (
                                <ul
                                    className="submenu"
                                    style={{
                                        top: `${submenuPosition.top}px`,
                                        left: `${submenuPosition.left}px`,
                                    }}
                                >
                                    {item.submenu.map((subItem, subIndex) => (
                                        <li
                                            key={subIndex}
                                            className="submenu-item"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Evita que el clic se propague al elemento padre
                                                handleMenuItemClick(subItem.path);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {subItem.title}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Botón de Toggle */}
            <button
                className="toggle-button"
                onClick={handleMenuToggle}
                style={{
                    left: isMenuOpen ? "165px" : "50px",
                }}
            >
                {isMenuOpen ? <FaChevronLeft /> : <FaChevronRight />}
            </button>

            {/* Nombre en la parte inferior */}
            <p className="name-span">Jake Wyatt</p>
        </nav>
    );
}

export default LeftMenu;
