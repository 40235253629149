import React, { useEffect, useState } from 'react';
import '../style/pages/ProductionRepresentatives.css';

function OriginalRepresentatives() {
    const [iframeUrl, setIframeUrl] = useState("");
    const API_URL = process.env.REACT_APP_API_URL

    useEffect(() => {
        // Llamada al backend para obtener la URL del iframe
        const fetchIframeUrl = async () => {
            try {
                const response = await fetch(`${API_URL}/metabase_api/get_dashboard_iframe/3`);  // Asegúrate de ajustar la URL y dashboard_id
                const data = await response.json();
                setIframeUrl(data.iframe_url);
            } catch (error) {
                console.error("Error fetching iframe URL:", error);
            }
        };

        fetchIframeUrl();
    }, [API_URL]);

    return (
        <div className="representatives-layout-production">
            {iframeUrl ? (
                <iframe
                    src={iframeUrl}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    allowTransparency="true"
                    title="Representatives Dashboard"
                />
            ) : (
                <p>Loading dashboard...</p>
            )}
        </div>

    );
}

export default OriginalRepresentatives;
